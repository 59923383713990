.navbar{

background-color: #fff;

}
/* #agenda {
    scroll-margin: 60px;
  } */

  /* #video {
    scroll-margin: 160px;
  } */

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
.loginbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
.registerbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
img.demo {
    filter: brightness(120%);
    filter: contrast(120%);
    filter: drop-shadow(10px 10px 10px black);
 }